import React, { FC, useCallback, useRef, useMemo } from "react";
import {
  Typography,
  useTheme,
  Fade,
  Popper,
  ClickAwayListener
} from "utils/material-ui-core";
import { CatalogNavigationProps } from "./types";
import {
  CATALOG_NAV_CONCEPTS_ID,
  CATALOG_NAV_BABY_AND_CHILD_ID,
  CATALOG_NAV_TEEN_ID,
  catalogLinksToEnable,
  CATALOG_SALE_NAME,
  CATALOG_SALE_COLOR,
  RHR_FADE_TIMEOUT_DURATION_MIN
} from "utils/constants";
import analyticsLoader from "analytics/loader";
import RHRArrowIcon from "icon-arrow-rhr";
import RHChevronIcon from "icon-chevron";
import memoize from "utils/memoize";
import NavigationLink from "container-inline-header/NavigationLink";
import RHLogo from "component-rh-logo";
import useBrand from "hooks-use-brand/useBrand";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { useQuery } from "@apollo/client";
import { querySaleCatalogNavigation } from "graphql-client/queries/navigation";
import useSite from "hooks/useSite";
import useLocale from "hooks-use-locale/useLocale";
import { useTypographyStyles } from "hooks/useTypographyStyles";
import RHRSaleNavigation from "./RHRSaleNavigation";
import CatalogNavigationDetails from "container-rhr-header/RHRCatalogNavigationDetails";
import { useAppId } from "hooks/useAppId";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import { useCountry } from "hooks/useCountry";
import { useRhUserAtomValue } from "hooks/atoms";
import { useIsoCookies } from "hooks/useIsoCookies";
import classNames from "classnames";
import TailwindPopper from "component-tailwind-popper";

export const CatalogNavigation: FC<CatalogNavigationProps> = ({
  catalogNavigation,
  isDarkMode,
  onChangeSelectedNavItem,
  selectedNavItem,
  onCategoryDrawerClose,
  justify,
  isAem,
  color,
  disabled,
  showBackToRH = false,
  backToHomeLink,
  backtoRHLabel = "BACK TO RH", // Fallback text here
  styles
}) => {
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseLink4"]
  });
  const nextgenPg = useIsoCookies(["nextgenpg"])?.nextgenpg;
  const brand = useBrand();
  const theme = useTheme();
  const siteId = useSite();
  const env = useEnv();
  const { isConcierge } = useAppId();
  const FEATURE_STICKY_HEADER = yn(env.FEATURE_STICKY_HEADER);
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const { userType } = useRhUserAtomValue();
  const locale = useLocale();
  const countryCode = useCountry();

  const navListRef = useRef([]);

  const hasNoSubCategories: boolean | undefined = useMemo(
    () =>
      selectedNavItem?.childCategories?.every(
        child => !child?.childCategories?.length
      ),
    [selectedNavItem]
  );

  const hasNoSecondarySubCategories: boolean | undefined = useMemo(
    () =>
      selectedNavItem?.childCategories?.every(child => {
        return (
          !child?.childCategories?.length ||
          child.childCategories.every(
            secondaryChild => !secondaryChild?.childCategories?.length
          )
        );
      }),
    [selectedNavItem]
  );

  const themeColor = color
    ? color
    : isDarkMode
    ? theme.palette.common.white
    : theme.palette.common.black;

  const handleNavItemClick = (
    e: React.SyntheticEvent,
    navElement: NavigationElement,
    isEnabledLink: boolean
  ) => {
    const target = e.target as HTMLElement;
    const id = (target as HTMLElement)?.id;
    if (disabled) return false;
    e.stopPropagation();
    if (disabled) return false;
    if (!navElement?.childCategories?.length) {
      // If Nav Item dont have a child item then directly emit universal click event #BUGFIX/ERHR-108
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isHeaderNavigation: true,
              topCat: navElement?.displayName,
              parentCat: navElement?.displayName,
              cat: navElement?.targetUrl,
              catURL: navElement?.targetUrl,
              class: target?.className,
              id: target?.id || id
            }
          }
        })
      );

      onCategoryDrawerClose();
      if (navElement?.linkType === "none") {
        window.open(`${navElement?.targetUrl}`, "_blank");
      } else {
        window.open(`${navElement?.targetUrl}`, "_self");
      }
    } else {
      if (
        isEnabledLink &&
        navElement?.displayName?.toUpperCase() !==
          CATALOG_SALE_NAME.toUpperCase()
      ) {
        onCategoryDrawerClose();
      } else {
        handleDrawerOpen(e.currentTarget, navElement);
      }
    }
  };

  const handleDrawerOpen = useCallback(
    (selectedHTMLElement, navElement: NavigationElement) => {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE
        )
      );

      if (selectedNavItem?.id === navElement.id) {
        return onChangeSelectedNavItem?.(null, null);
      }

      return onChangeSelectedNavItem?.(navElement, selectedHTMLElement);
    },
    [onChangeSelectedNavItem, selectedNavItem?.id]
  );

  const {
    data: { saleCatalogNavigation: saleDataCatalogNavigation } = {} as Query
  } = useQuery<Query>(querySaleCatalogNavigation, {
    variables: {
      filter: "sale",
      siteId,
      locale,
      countryCode,
      nextgenPg: nextgenPg || "false"
    }
  });

  const navigationList =
    [...(catalogNavigation?.childCategories as NavigationElement[])]?.map(
      mainElement => {
        const upperCaseName =
          mainElement &&
          mainElement.displayName &&
          typeof mainElement.displayName === "string"
            ? mainElement.displayName.toUpperCase()
            : "";
        if (upperCaseName === CATALOG_SALE_NAME.toUpperCase()) {
          return {
            ...mainElement,
            childCategories: saleDataCatalogNavigation?.childCategories,
            linkType: "none"
          };
        } else return mainElement;
      }
    ) ?? [];

  const updatedNavigationList = isConcierge
    ? env.FEATURE_NAV_CONCIERGE
      ? navigationList?.filter(item => !(item.id === CATALOG_NAV_CONCEPTS_ID))
      : navigationList?.filter(
          item =>
            ![CATALOG_NAV_BABY_AND_CHILD_ID, CATALOG_NAV_TEEN_ID].includes(
              item.id ?? ""
            )
        )
    : navigationList;

  return (
    <ul
      className={`flex ${
        justify ? `justify-${justify}` : "justify-between"
      } flex-nowrap ${isAem ? "w-1/2 mx-auto my-[14px]" : "my-[14px]"} p-0`}
      role="menubar"
      aria-label="Main"
    >
      {showBackToRH && backToHomeLink && (
        <span
          className={`cursor-pointer absolute left-[60px] xl:left-[100px] flex items-center top-[-5px]`}
        >
          <RHChevronIcon htmlColor={isDarkMode ? "#fff" : "#000"} />
          <a
            href={backToHomeLink}
            className={`ml-[5px] ${
              isDarkMode ? "text-white" : "text-black"
            } no-underline hidden hover:underline ${
              isDarkMode ? "hover:text-white" : "hover:text-black"
            } md:inline-block`}
          >
            {backtoRHLabel}
          </a>
        </span>
      )}
      {updatedNavigationList?.length > 0
        ? updatedNavigationList?.map((navElement, index) => {
            const isSelected =
              selectedNavItem?.displayName == navElement?.displayName;
            let category;

            if (navElement?.targetUrl?.indexOf(".jsp") === -1) {
              category = navElement.targetUrl;
            } else {
              category = navElement?.targetUrl?.substring(
                0,
                navElement?.targetUrl?.indexOf(".jsp") + 4
              );
              if (category === "/content/category.jsp") {
                category = navElement.targetUrl;
              }
            }
            const lowerCaseDisplayName =
              navElement &&
              navElement.displayName &&
              typeof navElement.displayName === "string"
                ? navElement.displayName.toLowerCase()
                : "";
            const enabledLink =
              lowerCaseDisplayName === "sale"
                ? false
                : catalogLinksToEnable.includes(category);

            const isHiddenConciergeLink =
              isConcierge &&
              (navElement?.targetUrl?.includes("wish-list.jsp") ||
                navElement?.displayName?.toLowerCase() === "registry");

            return !isHiddenConciergeLink &&
              !(
                navElement?.targetUrl?.includes("gift-registry") &&
                (brand === "TN" ||
                  userType === "CONTRACT" ||
                  userType === "TRADE")
              ) ? (
              <>
                <div
                  id={`container-rhrheader-rhr-catalogNav_catalogNav-${navElement.id}`}
                  tabIndex={0}
                  aria-label={navElement.displayName}
                  aria-expanded={isSelected}
                  key={`catalogNav-${navElement.id}`}
                  data-navigation-account-item-id={
                    navElement.targetUrl === "/my-account/wish-list.jsp"
                      ? "wish-list"
                      : navElement.id
                  }
                  onKeyDown={handleKeyboardPressWithEvent(e =>
                    handleNavItemClick(e, navElement, enabledLink)
                  )}
                  onClick={e => handleNavItemClick(e, navElement, enabledLink)}
                  className={classNames([
                    navElement &&
                    navElement.displayName &&
                    typeof navElement.displayName === "string" &&
                    navElement?.displayName?.toUpperCase() ===
                      CATALOG_SALE_NAME.toUpperCase() &&
                    !isDarkMode
                      ? `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                          isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                        }] underline-offset-[5px] !hover:underline !hover:color-[#CA2022]`
                      : `flex !-mt-[8px] pt-[8px] no-underline decoration-[${
                          isDarkMode ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)"
                        }] underline-offset-[5px] !hover:underline !hover:color-[${
                          isDarkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"
                        }]`
                  ])}
                  ref={el => (navListRef.current[index] = el)}
                  style={
                    isSelected
                      ? {
                          textDecoration: "underline",
                          textDecorationColor: themeColor
                        }
                      : undefined
                  }
                >
                  {navElement.id === CATALOG_NAV_CONCEPTS_ID ? (
                    <div
                      data-testid="catalog-nav-concepts"
                      className={`grid grid-cols-2 w-full h-full items-center cursor-pointer`}
                    >
                      <div>
                        <RHLogo
                          onClick={e => e.preventDefault()}
                          brand={brand}
                          id={"nav-logo-img"}
                          primary={isDarkMode ? true : false}
                          imgStyle={{
                            height: styles?.RHLogoHeight || "10px",
                            width: "auto"
                          }}
                          logoDirName={
                            (styles?.brandDropdownLogosDirName || "") as string
                          }
                        />
                      </div>
                      <div>
                        <RHRArrowIcon
                          className="ml-[8px] text-[8px] transition-transform duration-300 rotate-90 "
                          strokeColor={themeColor}
                          style={
                            isSelected
                              ? { transform: "rotate(270deg)" }
                              : {
                                  fontSize: theme.spacing(
                                    (styles?.rhLogoArrowFont as number) || 1
                                  ),
                                  fill: "none"
                                }
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <NavigationLink
                      to={navElement.targetUrl}
                      noLink={!enabledLink}
                      style={disabled ? { pointerEvents: "none" } : undefined}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color:
                            navElement &&
                            navElement.displayName &&
                            typeof navElement.displayName === "string" &&
                            navElement?.displayName?.toUpperCase() ===
                              CATALOG_SALE_NAME.toUpperCase()
                              ? CATALOG_SALE_COLOR
                              : themeColor,
                          fontWeight: 400,
                          textTransform: "uppercase"
                        }}
                        className={`${
                          FEATURE_STICKY_HEADER &&
                          typographyClasses?.rhBaseLink4
                        }`}
                        dangerouslySetInnerHTML={{
                          __html:
                            navElement &&
                            navElement.displayName &&
                            typeof navElement.displayName === "string"
                              ? navElement.displayName?.replace(/<br>/g, "")
                              : ""
                        }}
                      />
                    </NavigationLink>
                  )}
                </div>
                {/* Catalog Navigation Menu Popper */}
                {FEATURE_TAILWIND_COMPONENTS ? (
                  <TailwindPopper
                    open={true}
                    anchorEl={navListRef.current[index]}
                    placement={
                      selectedNavItem?.displayName?.toLowerCase() === "sale"
                        ? "bottom-end"
                        : "bottom-start"
                    }
                    disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: false
                      }
                    }}
                    className={`ml-[${
                      selectedNavItem?.displayName?.toLowerCase() === "sale"
                        ? "unset"
                        : "-20px"
                    }] mt-[15px] bg-[#f9f7f4] shadow-[0px_2px_4px_rgba(0,0,0,0.1)] z-40 ${
                      selectedNavItem?.displayName?.toLowerCase() === "sale" &&
                      !hasNoSecondarySubCategories
                        ? "w-[680px]"
                        : hasNoSubCategories
                        ? "w-[222px]"
                        : "w-[422px]"
                    } p-10 absolute top-0 left-0 transform ${
                      isSelected ? "" : "hidden"
                    }`}
                  >
                    <div
                      className={`transition-opacity duration-[10ms] ${
                        isSelected
                          ? "animate-fadeIn opacity-100"
                          : "animate-fadeOut opacity-0"
                      }`}
                    >
                      <div>
                        <ClickAwayListener
                          onClickAway={() => {
                            isSelected && onCategoryDrawerClose();
                          }}
                        >
                          <div>
                            {navElement?.displayName?.toLowerCase() ===
                            "sale" ? (
                              <RHRSaleNavigation
                                selectedCatalogNavItem={navElement}
                                onCategoryDrawerClose={onCategoryDrawerClose}
                                isSelected={isSelected}
                              />
                            ) : (
                              <CatalogNavigationDetails
                                selectedCatalogNavItem={navElement ?? {}}
                                onCategoryDrawerClose={onCategoryDrawerClose}
                                hasNoSubCategories={hasNoSubCategories}
                                isSelected={isSelected}
                              />
                            )}
                          </div>
                        </ClickAwayListener>
                      </div>
                    </div>
                  </TailwindPopper>
                ) : (
                  <Popper
                    open={true}
                    anchorEl={navListRef.current[index]}
                    placement={
                      selectedNavItem?.displayName?.toLowerCase() === "sale"
                        ? "bottom-end"
                        : "bottom-start"
                    }
                    disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: false
                      }
                    }}
                    className={`ml-[${
                      selectedNavItem?.displayName?.toLowerCase() === "sale"
                        ? "unset"
                        : "-20px"
                    }] mt-[15px] bg-[#f9f7f4] shadow-[0px_2px_4px_rgba(0,0,0,0.1)] z-40 ${
                      selectedNavItem?.displayName?.toLowerCase() === "sale" &&
                      !hasNoSecondarySubCategories
                        ? "w-[680px]"
                        : hasNoSubCategories
                        ? "w-[222px]"
                        : "w-[422px]"
                    } p-10 absolute top-0 left-0 transform ${
                      isSelected ? "" : "hidden"
                    }`}
                    transition
                    tabIndex={-1}
                  >
                    {({ TransitionProps }) => (
                      <Fade
                        {...TransitionProps}
                        timeout={RHR_FADE_TIMEOUT_DURATION_MIN}
                      >
                        <div>
                          <ClickAwayListener
                            onClickAway={() => {
                              isSelected && onCategoryDrawerClose();
                            }}
                          >
                            <div>
                              {navElement?.displayName?.toLowerCase() ===
                              "sale" ? (
                                <RHRSaleNavigation
                                  selectedCatalogNavItem={navElement}
                                  onCategoryDrawerClose={onCategoryDrawerClose}
                                  isSelected={isSelected}
                                />
                              ) : (
                                <CatalogNavigationDetails
                                  selectedCatalogNavItem={navElement ?? {}}
                                  onCategoryDrawerClose={onCategoryDrawerClose}
                                  hasNoSubCategories={hasNoSubCategories}
                                  isSelected={isSelected}
                                />
                              )}
                            </div>
                          </ClickAwayListener>
                        </div>
                      </Fade>
                    )}
                  </Popper>
                )}
              </>
            ) : null;
          })
        : null}
    </ul>
  );
};

export default memoize(CatalogNavigation);
