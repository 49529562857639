import { createStyles, makeStyles, Theme } from "utils/material-ui-core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: "block !important",
      padding: `${theme.spacing(0, 0, 1.5)} !important`,
      "&first-child": {
        paddingTop: 0
      }
    },

    bigMarginOnLast: {
      "&:last-child": {
        marginTop: theme.spacing(3)
      }
    }
  })
);
