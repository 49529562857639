import React, { forwardRef, Ref } from "react";
import { Link, LinkProps } from "react-router-dom";
import { useNavigationStylesByBrand } from "hooks/useTypographyStyles";
import classNames from "classnames";
import memoize from "utils/memoize";

interface LinkpropsExt extends LinkProps {
  noLink?: boolean;
}
export const NavigationLink = forwardRef(
  (props: LinkpropsExt, ref: Ref<HTMLAnchorElement>) => {
    const { noLink, target = "_self", ...linkProps } = props;
    const typographyClasses = useNavigationStylesByBrand({
      keys: ["desktopCatalogLink"]
    });
    const targetUrl = props?.to?.toString();

    if (noLink) {
      return (
        <div
          data-nolink={props.dangerouslySetInnerHTML}
          className={classNames(typographyClasses.desktopCatalogLink)}
          dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        >
          {props.children}
        </div>
      );
    }
    try {
      new URL(targetUrl);
      return (
        <a
          ref={ref}
          href={targetUrl}
          target={target}
          style={props.style}
          className={classNames(typographyClasses.desktopCatalogLink)}
        >
          {props.children}
        </a>
      );
    } catch {
      return (
        <Link
          ref={ref}
          {...linkProps}
          target={target}
          className={classNames(typographyClasses.desktopCatalogLink)}
        >
          {props.children}
        </Link>
      );
    }
  }
);

export default memoize(NavigationLink);
