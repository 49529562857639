import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import analyticsLoader from "analytics/loader";
import {
  Theme,
  makeStyles,
  createStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import yn from "yn";
import { client } from "graphql-client";
import { ValidatorForm } from "react-material-ui-form-validator";
import WarningIcon from "@material-ui/icons/Warning";
import { Controller, useForm } from "react-hook-form";
import { RHFormTextField } from "component-rh-text-field";
import { emailRegex } from "utils/regex";
import { FONT_BARON_SANS_LIGHT, PRIVACY_URL } from "utils/constants";
import { useCountry } from "hooks/useCountry";
import { useSubscribeToBrands } from "hooks/useSubscribeToBrands";
import memoize from "utils/memoize";
import useBrand from "hooks-use-brand/useBrand";
import useLocale from "hooks-use-locale/useLocale";
import { Link } from "react-router-dom";
import { useLocalization } from "hooks/useLocalization";
import { useEnv } from "hooks/useEnv";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import {
  countries,
  keycloakLocaleMapper,
  europeanCountries
} from "resources/countries-config.json";
import { processModelResponse } from "graphql-client/queries/get-aem-model";
import { aemFetchHook } from "aem/fetchModel";
import RHSpinner from "components/RHSpinner";

type SignUpFormType = {
  [brand: string]: boolean;
} & {
  email: string;
};

const emailSmallLetterStyle = {
  fontSize: "10px",
  lineHeight: "15px",
  fontWeight: 300,
  fontFamily: FONT_BARON_SANS_LIGHT,
  color: "#666666"
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailForm: {
      maxWidth: "330px",
      margin: "0 auto 80px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto 40px"
      },
      [theme.breakpoints.down("xs")]: {
        margin: "40px auto"
      }
    },
    marginBottom40: {
      margin: "0 auto 40px",
      [theme.breakpoints.down("xs")]: {
        margin: "40px auto"
      }
    },
    errorWrap: {
      color: "#CA6667",
      lineHeight: "1.29",
      letterSpacing: "2px",
      flexDirection: "column"
    },
    errorText: {
      marginLeft: "5px",
      marginTop: "3px"
    },
    thankyouText: {
      paddingTop: "20px",
      lineHeight: "14px",
      letterSpacing: "0px",
      color: "#201f1f",
      textAlign: "center",
      fontSize: "13px",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT
    },
    title: {
      marginBottom: "5px",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: "27px !important",
        marginBottom: "6px"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px important",
        marginBottom: "6px"
      }
    },
    subTitle: {
      color: "#666666",
      lineHeight: "18px",
      textAlign: "center",
      margin: "0px auto 16px",
      fontSize: "12px",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT,
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px"
      }
    },
    inputField: {
      "& .MuiOutlinedInput-root": {
        background: "#fff"
      }
    },
    privacyPolicy: {
      paddingTop: "8px",
      paddingStart: "24px",
      ...emailSmallLetterStyle,
      "& a": {
        color: emailSmallLetterStyle.color
      }
    },
    termWrap: {
      margin: "auto",
      paddingTop: "10px",

      "& .MuiFormControlLabel-label": {
        ...emailSmallLetterStyle
      },
      "& .MuiCheckbox-root": {
        width: "16px",
        height: "16px"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "10px !important"
      }
    },
    termText: {
      fontSize: "10px",
      lineHeight: "15px",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT,
      letterSpacing: "0.015em",
      textAlign: "center"
    },
    errorMsg: {
      color: "#ca6667",
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "12px",
      letterSpacing: "0.04em",
      textAlign: "center",
      margin: "10px"
    },
    button: {
      fontFamily: FONT_BARON_SANS_LIGHT,
      color: " #000",
      fontWeight: 300,
      margin: "0 auto 0px",
      textTransform: "uppercase",
      fontSize: "10px",
      lineHeight: "15px",
      borderRadius: "0px",
      "&:focus": {
        outline: "#015fcc auto 1px"
      }
    },
    option: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      cursor: "pointer",
      height: "fit-content",
      paddingBottom: "12px",
      marginTop: "-2px"
    },
    optionLanguage: {
      color: "#201F1F",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "RHSans-ExtraLight",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "120%", // or '13.2px'
      letterSpacing: "0.44px",
      textTransform: "uppercase"
    },
    selectedOption: {
      fontWeight: 400,
      fontFamily: "RHSans-Roman",
      color: `${theme.palette.common.black} !important`,
      position: "relative",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "14px",
        height: "1px",
        backgroundColor: "black",
        bottom: "-1px",
        left: "0px"
      }
    },
    spinner: {
      marginBottom: "50px !important"
    }
  })
);
const brand = useBrand();
const EmailSignup: FC<{}> = () => {
  const classes = useStyles();
  const typoStyleClasses = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseH1", "textAlignCenter", "rhBaseLink2"]
  });
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [errorAlertList, setErrorAlertList] = useState<string[]>([]);
  const [form, setForm] = useState<HTMLFormElement | null>(null);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en-GB");
  const { handleSubmit, errors, control, getValues } = useForm({
    mode: "onBlur"
  });

  const env = useEnv();
  const country = useCountry();
  const locale = useLocale();
  const isEuCountry = checkCountryIsEu({ country });
  const prefix = useLocalization();

  const language = useMemo(
    () => keycloakLocaleMapper[selectedLanguage],
    [selectedLanguage]
  );

  const { data, loading } = aemFetchHook({
    path: `/admin/marketing-email-popup`,
    isJSPPage: false,
    isPage: true,
    graphqlClient: client
  });
  const pageContent = processModelResponse(data);

  const { subscribeToBrands, loading: loadingSubmit } = useSubscribeToBrands({
    onCompleted: () => {
      setSuccess(true);
    },
    onError: () => {
      setErrorAlertList(["Error"]);
    }
  });

  useEffect(() => {
    setSuccess(false);
    setIsSubmitted(false);
    setErrorAlertList([]);
    setAgreedTerms(false);
    const formEvent = document.querySelector<HTMLFormElement>(
      '[data-testid="rh-email-signup-form-container"]'
    );
    if (formEvent) {
      setForm(formEvent);
    }
  }, [country]);

  const onSubmit = useCallback(
    (formValue: SignUpFormType, event: React.FormEvent<HTMLFormElement>) => {
      // todo: FORM SUBMIT -> GA4
      formSubmitEventGA4(event);
      if (isEuCountry) {
        setIsSubmitted(true);
        if (!formValue.terms) return;
      }
      setErrorAlertList([]);
      setSuccess(false);
      subscribeToBrands({
        variables: {
          brands: [brand],
          country: country,
          email: formValue.email,
          locale
        }
      });
    },
    [subscribeToBrands, country, isEuCountry, locale]
  );
  const formStartEventGA4 = () => {
    if (!formChanged) {
      if (yn(env.FEATURE_ANALYTICS_FORM_DATALAYER)) {
        analyticsLoader(a => {
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.GA4_FORM_SUBMIT.INT_TYPE,
            {
              form: {
                form_id: form.getAttribute("data-testid"),
                form_start: true
              }
            }
          );
        });
      }
      setFormChanged(true);
    }
  };
  const formSubmitEventGA4 = (event: React.FormEvent<HTMLFormElement>) => {
    // todo: TRADE SALES -> FORM SUBMIT -> GA4
    if (yn(env.FEATURE_ANALYTICS_FORM_DATALAYER)) {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.GA4_FORM_SUBMIT.INT_TYPE,
          {
            form: {
              form_id: (event.target as HTMLElement)
                .closest("form")
                .getAttribute("data-testid")
            }
          }
        )
      );
    }
    setFormChanged(false);
  };

  const handleLanguageSelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const updatedValue = event.currentTarget.dataset["value"] || "en-GB";

    setSelectedLanguage(updatedValue);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classNames([
          classes.emailForm,
          isEuCountry && classes.marginBottom40
        ])}
      >
        {loading ? (
          <RHSpinner
            className={classes.spinner}
            style={{ position: "unset" }}
          />
        ) : (
          <>
            {errorAlertList.length > 0 && (
              <Grid className={classes.errorWrap}>
                <WarningIcon />
                <span className={classes.errorText}>
                  {pageContent?.FOOTER_EMAIL_SIGN_UP?.ERROR}
                </span>
              </Grid>
            )}

            <ValidatorForm
              debounceTime={1000}
              instantValidate={false}
              onChange={formStartEventGA4}
              onSubmit={handleSubmit(onSubmit)}
              data-testid="rh-email-signup-form-container"
              validationErrors={errorAlertList}
            >
              <Typography
                className={classNames([
                  typoStyleClasses.textAlignCenter,
                  typoStyleClasses.rhBaseH1,
                  classes.title
                ])}
              >
                {pageContent?.FOOTER_EMAIL_SIGN_UP?.TITLE}
              </Typography>

              <Typography className={classNames([classes.subTitle])}>
                {pageContent?.FOOTER_EMAIL_SIGN_UP?.SUB_TITLE}
              </Typography>
              {!yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE) &&
                countries[country].supportedLanguage.length > 1 && (
                  <Grid
                    item
                    container
                    justify="center"
                    className={classNames(classes.option)}
                  >
                    {countries[country].supportedLanguage.map(
                      currentLanguage => {
                        const isSelected =
                          selectedLanguage?.includes(currentLanguage);
                        const currentlanguageValue = `${currentLanguage}-${
                          europeanCountries.includes(country) &&
                          currentLanguage === "en"
                            ? "GB"
                            : country
                        }`;
                        return (
                          <>
                            <div
                              key={`${currentLanguage}`}
                              data-value={currentlanguageValue}
                              className={classNames(classes.optionLanguage, {
                                [classes.selectedOption]: isSelected
                              })}
                              onClick={handleLanguageSelection}
                              tabIndex={0}
                              onKeyPress={handleKeyboardPressWithEvent(
                                handleLanguageSelection
                              )}
                            >
                              {currentLanguage?.toUpperCase()}
                            </div>
                          </>
                        );
                      }
                    )}
                  </Grid>
                )}
              {!success ? (
                <>
                  <RHFormTextField
                    control={control}
                    fullWidth
                    name="email"
                    className={classes.inputField}
                    label={pageContent?.FOOTER_EMAIL_SIGN_UP?.EMAIL}
                    error={errors.email}
                    rules={{
                      required: pageContent?.FOOTER_EMAIL_SIGN_UP?.EMAIL_ERROR,
                      pattern: {
                        value: emailRegex,
                        message: pageContent?.FOOTER_EMAIL_SIGN_UP?.EMAIL_ERROR
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            type="submit"
                            name="submit"
                            className={classNames(classes.button)}
                            disabled={
                              loadingSubmit || (isEuCountry && !agreedTerms)
                            }
                          >
                            {pageContent?.FOOTER_EMAIL_SIGN_UP?.SIGNUP}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />

                  {isEuCountry && (
                    <>
                      <Grid className={classes.termWrap}>
                        <Controller
                          control={control}
                          fullWidth
                          name="terms"
                          render={({ onChange, onBlur, value }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  aria-label="terms and conditions"
                                  id="terms"
                                  onBlur={onBlur}
                                  tabIndex={-1}
                                  checked={value || ""}
                                  onChange={e => {
                                    setAgreedTerms(e.target.checked);
                                    onChange(e.target.checked);
                                  }}
                                />
                              }
                              tabIndex={0}
                              onKeyDown={handleKeyboardPressWithEvent(() => {
                                setAgreedTerms(!agreedTerms);
                                onChange(!agreedTerms);
                              })}
                              label={
                                pageContent?.FOOTER_EMAIL_SIGN_UP
                                  ?.SUBSCRIPTION_AGREE
                              }
                              color="primary"
                            />
                          )}
                        />
                      </Grid>
                      <Typography className={classes.privacyPolicy}>
                        {`${pageContent?.FOOTER_EMAIL_SIGN_UP?.TERMS_AGREE} `}
                        <Link color="inherit" to={`${prefix}/${PRIVACY_URL}`}>
                          {pageContent?.FOOTER_EMAIL_SIGN_UP?.RH_PRIVACY_NOTICE}
                        </Link>
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography className={classNames([classes.thankyouText])}>
                  {pageContent?.FOOTER_EMAIL_SIGN_UP?.THANKYOU_SUBTEXT}
                </Typography>
              )}
            </ValidatorForm>
          </>
        )}
      </Grid>
    </>
  );
};

export default memoize(EmailSignup);
