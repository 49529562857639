import React, { FC, SyntheticEvent, useCallback, useState } from "react";
import { useHistory } from "react-router";
import buildPath from "utils/buildPath";
import classNames from "classnames";
import RHImage from "component-rh-image";
import { List, ListItem, Typography } from "utils/material-ui-core";
import { useStyles } from "container-rhr-header/WORHMenuContent/style";
import { useLocalization } from "hooks/useLocalization";
import { hasLocale } from "utils/intlUtils";
interface WORHMenuParentItemProps {
  expandedNavItem: NavigationElement | null;
  menuItem: NavigationElement;
  rootCategory: NavigationElement;
  onClick: (e: SyntheticEvent, navElement: NavigationElement) => void;
  setExpandedNavItem: (navItem: NavigationElement | null) => void;
}
import useIsoRedirect from "hooks-use-isoredirect";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";

interface WORHNavigationContentProps {
  menuItems: Array<NavigationElement>;
  bottomLinks: Array<NavigationElement>;
  onClose: () => void;
}

const WORHMenuContent: FC<WORHNavigationContentProps> = ({
  menuItems,
  bottomLinks,
  onClose
}) => {
  const classes = useStyles();
  const history = useHistory();
  const bottomLinksArray = bottomLinks
    ? Array.isArray(bottomLinks)
      ? bottomLinks
      : Object.values(bottomLinks)
    : [];
  const prefix = useLocalization();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();

  const [expandedNavItem, setExpandedNavItem] =
    useState<NavigationElement | null>(null);
  const handleNavigationClick = useCallback(
    (e: SyntheticEvent, navElement: NavigationElement) => {
      e.preventDefault();
      setExpandedNavItem(navElement);
      const pathHasSlash = navElement?.targetUrl?.charAt(0) === "/";
      const target = `${
        pathHasSlash
          ? hasLocale(navElement?.targetUrl)
            ? ""
            : prefix
          : navElement?.targetUrl?.startsWith("http")
          ? ""
          : `${prefix}/`
      }${navElement?.targetUrl}`;

      if (navElement?.linkType === "none") {
        return useIsoRedirect(navElement?.targetUrl || "", "windowOpen");
      }

      if (navElement?.targetUrl?.trim().indexOf("http") === 0) {
        useIsoRedirect(navElement?.targetUrl, "windowAssign");
      } else {
        history.push(buildPath(target));
      }
    },
    [history, onClose]
  );
  const menuItemsArray = menuItems
    ? Array.isArray(menuItems)
      ? menuItems
      : Object.values(menuItems)
    : [];
  return (
    <div
      className={"relative bg-[#f9f7f4] h-full flex flex-col justify-between"}
      style={isSSRToggledWithCSR ? { overflow: "hidden" } : {}}
    >
      <div className={"w-full h-4/5 overflow-scroll pb-0 z-[1] xl:h-[85%]"}>
        <div className={"h-full !pt-[16px] pb-[8px] md:px-[40px] xl:px-[80px]"}>
          <div className={`grid grid-cols-4 w-full h-full gap-8`}>
            {(menuItemsArray as NavigationElement[]).map((column, index) => {
              const smallTitle = column?.displayName?.split(" ")[0] ?? "";
              const largeTitle =
                column?.displayName?.split(" ").slice(1).join(" ") ?? "";

              return (
                <div key={column.id}>
                  {column.navImage && (
                    <a
                      aria-label={column?.displayName}
                      data-worh-type="worh-icon"
                      href={column.targetUrl || ""}
                      className={"relative hover:cursor-pointer"}
                      onClick={e => handleNavigationClick(e, column)}
                    >
                      <RHImage src={column.navImage} />
                    </a>
                  )}
                  <a
                    data-worh-type="worh-icon"
                    href={column.targetUrl || ""}
                    className={"block mt-[24px] no-underline"}
                    onClick={e => handleNavigationClick(e, column)}
                    tabIndex={0}
                    aria-hidden="false"
                  >
                    <Typography
                      variant="body1"
                      className={"!font-[CaslonRH-Thin] !mb-[-8px] !uppercase"}
                    >
                      {smallTitle + " "}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={"!uppercase !font-[CaslonRH-Thin]"}
                    >
                      {largeTitle}
                    </Typography>
                  </a>
                  <List disablePadding className={"!pt-[24px]"}>
                    {column.childCategories?.map(navItem => {
                      const isSelected =
                        navItem?.displayName === expandedNavItem?.displayName;
                      return (
                        <>
                          <ListItem
                            key={navItem.id}
                            className={classNames(classes.listItem, {
                              [classes.bigMarginOnLast]: index === 0
                            })}
                            aria-label={navItem?.displayName?.replace(
                              /<br>/g,
                              ""
                            )}
                            aria-expanded={isSelected}
                          >
                            <a
                              data-worh-type="worh-icon"
                              data-analytics-rootcat={column.displayName}
                              href={navItem.targetUrl || ""}
                              onClick={e => handleNavigationClick(e, navItem)}
                              className={
                                "underline-offset-[5px] decoration-transparent transition-all !duration-700 hover:decoration-black hover:underline hover:cursor-pointer"
                              }
                              tabIndex={0}
                              aria-hidden="false"
                            >
                              <Typography
                                className={"!uppercase !font-primary-light"}
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    navItem?.displayName?.replace(
                                      /<br>/g,
                                      ""
                                    ) || ""
                                }}
                              />
                            </a>
                            {(navItem.childCategories?.length ?? 0 > 0) && (
                              <List disablePadding className={"!pt-[12px]"}>
                                {navItem.childCategories?.map(
                                  grandchildNavItem => (
                                    <ListItem
                                      key={grandchildNavItem.id}
                                      className={classes.listItem}
                                    >
                                      <a
                                        data-worh-type="worh-icon"
                                        data-analytics-rootcat={
                                          column.displayName
                                        }
                                        data-analytics-parentCat={
                                          navItem.displayName
                                        }
                                        href={grandchildNavItem.targetUrl || ""}
                                        onClick={e =>
                                          handleNavigationClick(
                                            e,
                                            grandchildNavItem
                                          )
                                        }
                                        className={
                                          "underline-offset-[5px] decoration-transparent transition-all !duration-700 hover:decoration-black hover:underline hover:cursor-pointer"
                                        }
                                        tabIndex={0}
                                        aria-hidden="false"
                                      >
                                        <Typography
                                          className={"!capitalize"}
                                          variant="body1"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              grandchildNavItem?.displayName?.replace(
                                                /<br>/g,
                                                ""
                                              ) || ""
                                          }}
                                        />
                                      </a>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            )}
                          </ListItem>
                        </>
                      );
                    })}
                  </List>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {bottomLinksArray && bottomLinksArray.length > 0 && (
        <div
          className={
            "flex bg-[#f1f0Ed] z-[1] sticky bottom-0 h-16 px-[40px] xl:px-[80px] items-center"
          }
        >
          {bottomLinks.map(bottomLink => (
            <div className={"!mr-[36px]"}>
              <a
                data-worh-type="worh-footer"
                href={bottomLink.targetUrl || ""}
                onClick={e => handleNavigationClick(e, bottomLink)}
                className={
                  "underline-offset-[5px] decoration-transparent transition-all !duration-700 hover:decoration-black hover:underline hover:cursor-pointer"
                }
              >
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: bottomLink?.displayName?.replace(/<br>/g, "") || ""
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WORHMenuContent;
