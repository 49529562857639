import React, { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  Divider,
  MenuItem
} from "@material-ui/core";
import yn from "yn";
import RHStandardSelect from "component-rh-standard-select";
import { RHFormTextField } from "component-rh-text-field";
import { useEnv } from "hooks/useEnv";
import {
  countries,
  languages,
  measurements,
  shortNamesLanguageMeasurement
} from "resources/countries-config.json";
import { COUNTRY, LANGUAGE, MEASUREMENT } from "utils/constants";
import useUserPreferences from "hooks/useUserPreferences";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import memoize from "utils/memoize";
import RHRFlagIcon from "icons/RHRFlagIcon";
import { useTypographyStyles } from "hooks/useTypographyStyles";
import classNames from "classnames";

const useMenuItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "transparent !important",
      minWidth: 202,
      minHeight: 32,
      fontSize: "11px",
      color: "#808080",
      cursor: "pointer",
      padding: "8px 32px !important",
      "&:last-child": {
        borderBottom: "none",
        marginBottom: "10px"
      },
      "&.Mui-selected": {
        backgroundColor: "#F1F1F1 !important",
        color: "#000000"
      },
      "&:hover": {
        backgroundColor: "#F1F1F1 !important"
      }
    }
  })
);

const UserPreferences: FC<{ classes: ClassNameMap }> = ({ classes }) => {
  const { country, language, measurement, handleSaveCookies, ...rest } =
    useUserPreferences();

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(375));
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const menuItemClasses = useMenuItemStyles();

  const typographyStyles = useTypographyStyles({
    keys: ["uppercaseText"]
  });

  const environment = useEnv();

  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    environment.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const FEATURE_INTERNATIONAL_MEASUREMENT = yn(
    environment?.FEATURE_INTERNATIONAL_MEASUREMENT
  );
  const FEATURE_SUPPRESS_UK = yn(environment?.FEATURE_SUPPRESS_UK);
  const FEATURE_EU_EXPANSION_DE = yn(environment.FEATURE_EU_EXPANSION_DE);
  const FEATURE_EU_EXPANSION_BE = yn(environment.FEATURE_EU_EXPANSION_BE);
  const FEATURE_EU_EXPANSION_ES = yn(environment.FEATURE_EU_EXPANSION_ES);
  const FEATURE_EU_EXPANSION_FR = yn(environment.FEATURE_EU_EXPANSION_FR);

  const dropdownOptions = useMemo(() => {
    let countriesResult = Object.values(countries);
    let languagesResult = [...languages];

    if (FEATURE_SUPPRESS_UK) {
      countriesResult = countriesResult.filter(
        country => country.value !== "GB"
      );
    }

    if (!FEATURE_EU_EXPANSION_DE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "DE"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "de"
      );
    }

    if (!FEATURE_EU_EXPANSION_BE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "BE"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "nl"
      );
    }
    if (!FEATURE_EU_EXPANSION_ES) {
      countriesResult = countriesResult.filter(
        country => country.value !== "ES"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "es"
      );
    }
    if (!FEATURE_EU_EXPANSION_FR) {
      countriesResult = countriesResult.filter(
        country => country.value !== "FR"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "fr"
      );
    }

    return { countries: countriesResult, languages: languagesResult };
  }, [
    FEATURE_SUPPRESS_UK,
    FEATURE_EU_EXPANSION_DE,
    FEATURE_EU_EXPANSION_BE,
    FEATURE_EU_EXPANSION_ES,
    FEATURE_EU_EXPANSION_FR
  ]);

  const methods = useForm({
    defaultValues: {}
  });

  const {
    control,
    formState: { errors }
  } = methods;

  const handlePreferenceChange = (
    name: "Language" | "Measurement" | "Country"
  ) => {
    const lowerName = name.toLowerCase();
    return (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const target = e.target as HTMLElement;
      const id = (target?.parentNode as HTMLElement)?.id;
      rest[`set${name}`](e?.target?.value);
      if (name === "Country") {
        const countryValue = countries?.[e?.target?.value]?.defaultValues;
        rest.setMeasurement(countryValue?.measurement);
        document?.body?.dispatchEvent(
          new CustomEvent("cta_click", {
            detail: {
              item: {
                isCountrySwitch: true,
                fromFooter: true,
                country: countryValue?.country,
                language: language,
                class: target?.className,
                id: target?.id || id
              }
            }
          })
        );
      }
      handleSaveCookies({ [lowerName]: e?.target?.value });
    };
  };

  return (
    <>
      {smDown || xsDown ? (
        <>
          <div className={classes.shippingSeparator}>
            <Divider />
          </div>
          <div className={classes.smCountryPreferences}>
            <RHFormTextField
              name="country"
              id="country"
              type="text"
              margin="none"
              select
              control={control}
              inputProps={{
                "data-testid": "country"
              }}
              fullWidth
              value={country}
              defaultValue={country}
              onInputChange={handlePreferenceChange("Country")}
              SelectProps={{
                classes: {
                  root: classes.smSelect,
                  icon: classes.smSelectIcon
                },
                native: false
              }}
              InputLabelProps={{
                shrink: true
              }}
            >
              <div className={classes.headerOptionWrapper}>
                <p className={classes.headerOption}>{COUNTRY} </p>
                <p className={classes.headerOptionSelected}>
                  {`(${shortNamesLanguageMeasurement[country]})`}
                </p>
              </div>
              {dropdownOptions.countries.map(option => (
                <MenuItem
                  classes={menuItemClasses}
                  key={`qty-${option.value}`}
                  value={option.value}
                >
                  <div
                    className={classNames([
                      classes.itemWrapper,
                      typographyStyles.uppercaseText
                    ])}
                  >
                    <img className={classes.imageStyle} src={option.flag} />
                    {option.label}
                  </div>
                </MenuItem>
              ))}
            </RHFormTextField>
            {FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE && (
              <RHFormTextField
                name="language"
                id="language"
                type="text"
                margin="none"
                select
                control={control}
                inputProps={{
                  "data-testid": "language"
                }}
                fullWidth
                value={language}
                defaultValue={language}
                onInputChange={handlePreferenceChange("Language")}
                SelectProps={{
                  classes: {
                    root: classes.smSelect,
                    icon: classes.smSelectIcon
                  },
                  native: false
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                <div className={classes.headerOptionWrapper}>
                  <p className={classes.headerOption}>{LANGUAGE} </p>
                  <p className={classes.headerOptionSelected}>
                    {`(${shortNamesLanguageMeasurement[language]})`}
                  </p>
                </div>
                {dropdownOptions.languages.map(option => (
                  <MenuItem
                    classes={menuItemClasses}
                    key={`language-${option.value}`}
                    value={option.value}
                  >
                    <div className={typographyStyles.uppercaseText}>
                      {option.label}
                    </div>
                  </MenuItem>
                ))}
              </RHFormTextField>
            )}
            {FEATURE_INTERNATIONAL_MEASUREMENT ? (
              <RHFormTextField
                name="measurement"
                id="measurement"
                type="text"
                margin="none"
                select
                control={control}
                inputProps={{
                  "data-testid": "measurement"
                }}
                fullWidth
                value={measurement}
                defaultValue={measurement}
                onInputChange={handlePreferenceChange("Measurement")}
                SelectProps={{
                  classes: {
                    root: classes.smSelect,
                    icon: classes.smSelectIcon
                  },
                  native: false
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                <div className={classes.headerOptionWrapper}>
                  <p className={classes.headerOption}>{MEASUREMENT} </p>
                </div>
                {measurements.map(option => (
                  <MenuItem
                    classes={menuItemClasses}
                    key={`measurement-${option.value}`}
                    value={option.value}
                  >
                    <div className={typographyStyles.uppercaseText}>
                      {option?.label} {option?.units}
                    </div>
                  </MenuItem>
                ))}
              </RHFormTextField>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className={classes.countryPreferencesWrapper}>
            <div className={classes.countryPreferences}>
              <RHStandardSelect
                id="country-selection"
                onChange={handlePreferenceChange("Country")}
                value={country}
                SelectProps={{
                  classes: {
                    root: classes.select,
                    icon: classes.selectIcon
                  },
                  disableUnderline: true,
                  native: false,
                  className: "RHStandardSelect RHFooterSelect",
                  MenuProps: {
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                <div className={classes.headerOptionWrapper}>
                  <p className={classes.headerOption}>{COUNTRY} </p>
                  <p className={classes.headerOptionSelected}>
                    {`(${shortNamesLanguageMeasurement[country]})`}
                  </p>
                </div>
                <div className={classes.headerOptionDivider}></div>
                {dropdownOptions.countries.map(item => (
                  <MenuItem
                    classes={menuItemClasses}
                    key={`qty-${item.value}`}
                    value={item.value}
                  >
                    <div
                      className={typographyStyles.uppercaseText}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <RHRFlagIcon
                        country={item.value}
                        className={classes.imageStyle}
                      />
                      {item.label}
                    </div>
                  </MenuItem>
                ))}
              </RHStandardSelect>
              {FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE && (
                <RHStandardSelect
                  id="language-selection"
                  onChange={handlePreferenceChange("Language")}
                  value={language}
                  SelectProps={{
                    classes: {
                      root: classes.select,
                      icon: classes.selectIcon
                    },
                    disableUnderline: true,
                    native: false,
                    className: "RHFooterSelect",
                    MenuProps: {
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <div className={classes.headerOptionWrapper}>
                    <p className={classes.headerOption}>{LANGUAGE} </p>
                    <p className={classes.headerOptionSelected}>
                      {`(${shortNamesLanguageMeasurement[language]})`}
                    </p>
                  </div>
                  <div className={classes.headerOptionDivider}></div>
                  {dropdownOptions.languages.map(item => (
                    <MenuItem
                      classes={menuItemClasses}
                      key={`language-${item.value}`}
                      value={item.value}
                    >
                      <div className={typographyStyles.uppercaseText}>
                        {item.label}
                      </div>
                    </MenuItem>
                  ))}
                </RHStandardSelect>
              )}
              {FEATURE_INTERNATIONAL_MEASUREMENT ? (
                <RHStandardSelect
                  id="measurement-selection"
                  onChange={handlePreferenceChange("Measurement")}
                  value={measurement}
                  SelectProps={{
                    classes: {
                      root: classes.select,
                      icon: classes.selectIcon
                    },
                    disableUnderline: true,
                    native: false,
                    className: "RHFooterSelect",
                    MenuProps: {
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <div className={classes.headerOptionWrapper}>
                    <p className={classes.headerOption}>{MEASUREMENT} </p>
                  </div>
                  <div className={classes.headerOptionDivider}></div>

                  {measurements.map(item => (
                    <MenuItem
                      classes={menuItemClasses}
                      key={`measurement-${item.value}`}
                      value={item.value}
                    >
                      <div className={typographyStyles.uppercaseText}>
                        {item?.label}
                      </div>
                    </MenuItem>
                  ))}
                </RHStandardSelect>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memoize(UserPreferences);
