import React, { FC, useCallback, useMemo } from "react";
import { Divider, Typography, Grid } from "@material-ui/core";
import ExternalLinkArrowIcon from "icons/ExternalLinkArrowIcon";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import memoize from "utils/memoize";
import EmailSignup from "./EmailSignup";
import { useCountry } from "hooks/useCountry";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { usePageContentNoProxy } from "customProviders/LocationProvider";
import { useFetchModel } from "hooks/useFetchModel";
import { DE, NA_COUNTRIES } from "utils/constants";
import { useRhUserAtomValue } from "hooks/atoms";
import { useLocalization } from "hooks/useLocalization";

const AEMFooterContent: FC<{
  classes: ClassNameMap;
  brand?: string;
  isInEditor?: boolean;
}> = ({ classes, brand, isInEditor }) => {
  const {
    FEATURE_EMAIL_MARKETING_FOOTER_NA,
    FEATURE_EMAIL_MARKETING_FOOTER_UK,
    FEATURE_EMAIL_MARKETING_FOOTER_DE,
    FEATURE_EMAIL_MARKETING_FOOTER_BE,
    FEATURE_EMAIL_MARKETING_FOOTER_ES,
    FEATURE_EMAIL_MARKETING_FOOTER_FR
  } = useEnv();
  const ATGPagesToExcludePrefix = [
    "catalog-request.jsp",
    "contact-us.jsp",
    "sitemap.jsp"
  ];
  const prefix = useLocalization();
  const { pageContent } = !isInEditor
    ? usePageContentNoProxy()
    : useFetchModel(`/admin/footer-links`, false, false);
  const content = (pageContent as any)?.footerLinks;
  const { userType } = useRhUserAtomValue();
  const typographyclasses = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseBody2", "rhBaseCaption", "uppercaseText"]
  });
  const pageContentArray = content ? Object.keys(content) : [];
  const country = useCountry();
  const isEmailMarketingFooterNA = yn(FEATURE_EMAIL_MARKETING_FOOTER_NA);
  const isEmailMarketingFooterUK = yn(FEATURE_EMAIL_MARKETING_FOOTER_UK);
  const isEmailMarketingFooterDE = yn(FEATURE_EMAIL_MARKETING_FOOTER_DE);
  const isEmailMarketingFooterBE = yn(FEATURE_EMAIL_MARKETING_FOOTER_BE);
  const isEmailMarketingFooterES = yn(FEATURE_EMAIL_MARKETING_FOOTER_ES);
  const isEmailMarketingFooterFR = yn(FEATURE_EMAIL_MARKETING_FOOTER_FR);

  const getPrefixUrl = useCallback(url => {
    const index = ATGPagesToExcludePrefix.findIndex(val => {
      return url.includes(val);
    });
    if (index === -1) return prefix;
    else return "";
  }, []);

  const showEmailSignupComponent = useMemo((): boolean => {
    if (isEmailMarketingFooterNA && NA_COUNTRIES.includes(country)) {
      return true;
    }
    if (isEmailMarketingFooterUK && country === "GB") {
      return true;
    }
    if (isEmailMarketingFooterDE && country === DE) {
      return true;
    }
    if (isEmailMarketingFooterBE && country === "BE") {
      return true;
    }
    if (isEmailMarketingFooterES && country === "ES") {
      return true;
    }
    if (isEmailMarketingFooterFR && country === "FR") {
      return true;
    }
    return false;
  }, [
    country,
    isEmailMarketingFooterBE,
    isEmailMarketingFooterDE,
    isEmailMarketingFooterES,
    isEmailMarketingFooterFR,
    isEmailMarketingFooterNA,
    isEmailMarketingFooterUK
  ]);

  return content ? (
    <footer>
      <div
        className={classNames([
          classes.footerContainer,
          showEmailSignupComponent && classes.footerContainerwithEmail
        ])}
      >
        <div
          className={classNames([
            classes.separator,
            showEmailSignupComponent && classes.marketingSeparator
          ])}
        >
          <Divider />
        </div>
        <div>{showEmailSignupComponent && <EmailSignup />}</div>
        <div
          className={classNames([
            classes.footerMenus,
            showEmailSignupComponent && classes.footerMenusWithemail
          ])}
        >
          <Grid
            container
            justify="space-between"
            className={classes.grid} /* "grid grid-wide" */
          >
            {pageContentArray?.map((parentKey, i) => {
              const rowArray = content[parentKey]
                ? content[parentKey].items
                  ? Array.isArray(content[parentKey].items)
                    ? content[parentKey].items
                    : Object.values(content[parentKey].items)
                  : Array.isArray(content[parentKey])
                  ? content[parentKey]
                  : Object.values(content[parentKey])
                : [];
              return (
                <div key={parentKey}>
                  <div className={classes.gridItemInner}>
                    <Typography
                      className={classNames([
                        typographyclasses.rhBaseBody2,
                        typographyclasses.uppercaseText,
                        classes.footerHeader
                      ])}
                    >
                      {rowArray && rowArray[0] && rowArray[0].groupLabel
                        ? rowArray[0].groupLabel
                        : parentKey}
                    </Typography>
                    <ul>
                      {content &&
                        content[parentKey] &&
                        Array.isArray(content[parentKey]) &&
                        content[parentKey].map(
                          (
                            { text: subCategoryName = "", url = "", linkType },
                            j
                          ) => {
                            const isExternalLink = url?.startsWith("https://");
                            let urlHasLocale = false;
                            if (prefix?.length) {
                              urlHasLocale = prefix?.startsWith("/")
                                ? url?.includes(prefix.substring(1))
                                : url?.includes(prefix);
                            }

                            const prefixToPrepend = urlHasLocale
                              ? ""
                              : getPrefixUrl(url);
                            let sanitizedUrl = isExternalLink
                              ? url
                              : `${prefixToPrepend}${url}`;
                            if (!isExternalLink) {
                              sanitizedUrl = sanitizedUrl?.startsWith("/")
                                ? sanitizedUrl
                                : `/${sanitizedUrl}`;
                            }

                            const isDisableSignUpEmailLink =
                              subCategoryName?.includes("Sign up for Emails") &&
                              showEmailSignupComponent;

                            return !(
                              url?.includes("gift-registry") &&
                              (brand === "TN" ||
                                userType === "CONTRACT" ||
                                userType === "TRADE")
                            ) && !isDisableSignUpEmailLink ? (
                              <li key={`child_sub_category_${i}_${j}`}>
                                <a
                                  target={
                                    linkType === "none" || isExternalLink
                                      ? "_blank"
                                      : "_self"
                                  }
                                  href={sanitizedUrl}
                                  data-analytics-parentid={parentKey}
                                >
                                  <Typography
                                    variant="caption"
                                    className={classes.footerItems}
                                  >
                                    {subCategoryName
                                      .split(/\r?\n/)
                                      .map((line, i, array) => (
                                        <span key={i}>
                                          {line}
                                          {i !== array.length - 1 && <br />}
                                        </span>
                                      ))}
                                    {isExternalLink && (
                                      <ExternalLinkArrowIcon
                                        width={6.21}
                                        height={6.21}
                                        aria-hidden="false"
                                      />
                                    )}
                                  </Typography>
                                </a>
                              </li>
                            ) : null;
                          }
                        )}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Grid>
        </div>
      </div>
    </footer>
  ) : null;
};

export default memoize(AEMFooterContent);
