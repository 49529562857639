import { useTheme } from "utils/material-ui-core";

export default () => {
  const theme = useTheme();
  const giftRegistryContext = window.location.hostname.includes(
    "rhbabyandchild"
  )
    ? "BC_Registry"
    : "GiftRegistry";
  return [
    {
      id: "/customer-experience/email-signup",
      targetUrl: "/customer-experience/email-signup",
      displayName: "SIGN UP FOR EMAILS",
      "data-gtm-id": "footer-nav-email-signup",
      props: {
        style: {
          backgroundColor: "#f5f5f5",
          marginTop: theme.spacing(2),
          paddingTop: theme.spacing(2)
        }
      }
    },
    {
      id: "/store-locations/index.jsp",
      targetUrl: "/store-locations/index.jsp",
      displayName: "FIND A GALLERY",
      "data-gtm-id": "footer-nav-find-gallery",
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/customer-experience/catalog-request",
      targetUrl: "/customer-experience/catalog-request",
      displayName: "REQUEST A SOURCE BOOK",
      "data-gtm-id": "footer-nav-source-book",
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/membership.jsp?sale=false",
      targetUrl: "/membership.jsp?sale=false",
      displayName: "RH MEMBERS PROGRAMS",
      "data-gtm-id": "footer-nav-members-programs",
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/customer-service/index.jsp",
      targetUrl: "/customer-service/index.jsp",
      displayName: "CUSTOMER SERVICE",
      "data-gtm-id": "footer-nav-customer-service",
      childCategories: [
        {
          id: "/customer-service/shipping-and-delivery-info.jsp",
          targetUrl: "/customer-service/shipping-and-delivery-info.jsp",
          displayName: "Shipping & Delivery Information",
          "data-gtm-id": "footer-nav-shipping-delivery-information"
        },
        {
          id: "/content/page.jsp?id=placing-an-order",
          targetUrl: "/content/page.jsp?id=placing-an-order",
          displayName: "Placing An Order",
          "data-gtm-id": "footer-nav-placing-order"
        },
        {
          id: "/customer-service/return-policy.jsp",
          targetUrl: "/customer-service/return-policy.jsp",
          displayName: "Returns & Exchanges",
          "data-gtm-id": "footer-nav-returns-exchanges"
        },
        {
          id: "/customer-experience/contact-us",
          targetUrl: "/customer-experience/contact-us",
          displayName: "Contact Us",
          "data-gtm-id": "footer-nav-contact-us"
        },
        {
          id: "/customer-service/frequently-asked-questions.jsp",
          targetUrl: "/customer-service/frequently-asked-questions.jsp",
          displayName: "FAQ",
          "data-gtm-id": "footer-nav-faq"
        },
        {
          id: "/content/page.jsp?id=safety-recalls",
          targetUrl: "/content/page.jsp?id=safety-recalls",
          displayName: "Safety Recalls",
          "data-gtm-id": "footer-nav-safety-recalls"
        },
        {
          id: "/customer-service/restoration-hardware-credit-card.jsp",
          targetUrl: "/customer-service/restoration-hardware-credit-card.jsp",
          displayName: "RH Credit Card",
          "data-gtm-id": "footer-nav-credit-card"
        }
      ],
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/company-info/business-sales-and-gifts.jsp",
      targetUrl: "/company-info/business-sales-and-gifts.jsp",
      displayName: "GIFT CARD & REGISTRY",
      "data-gtm-id": "footer-nav-gift-card-registry",
      childCategories: [
        {
          id: "/gift-registry",
          targetUrl: `/content/category.jsp?context=${giftRegistryContext}`,
          displayName: "Gift Registry",
          "data-gtm-id": "footer-nav-gift-registry"
        },
        {
          id: "/catalog/category/products.jsp?cellBackground=false&categoryId=cat7440069",
          targetUrl:
            "/catalog/category/products.jsp?cellBackground=false&categoryId=cat7440069",
          displayName: "Gift Cards",
          "data-gtm-id": "footer-nav-gift-cards"
        }
      ],
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/company-info/index.jsp",
      targetUrl: "/company-info/index.jsp",
      displayName: "OUR COMPANY",
      "data-gtm-id": "footer-nav-our-company",
      childCategories: [
        {
          id: "/company-info/index.jsp",
          targetUrl: "/company-info/index.jsp",
          displayName: "About Us",
          "data-gtm-id": "footer-nav-about-us"
        },
        {
          id: "http://ir.rh.com/",
          targetUrl: "http://ir.rh.com/",
          displayName: "Investor Relations",
          "data-gtm-id": "footer-nav-investor-relations"
        },
        {
          id: "/company-info/jobs/index.jsp",
          targetUrl: "/company-info/jobs/index.jsp",
          displayName: "Careers",
          "data-gtm-id": "footer-nav-careers"
        },
        {
          id: "https://www.restorationhardware.online/",
          targetUrl: "https://www.restorationhardware.online/",
          displayName: "Press",
          "data-gtm-id": "footer-nav-press"
        },
        {
          id: "/customer-service/privacy-policy.jsp",
          targetUrl: "/customer-service/privacy-policy.jsp",
          displayName: "Privacy Policy",
          "data-gtm-id": "footer-nav-privacy-policy"
        },
        {
          id: "/content/page.jsp?id=terms-of-use",
          targetUrl: "/content/page.jsp?id=terms-of-use",
          displayName: "Terms Of Use",
          "data-gtm-id": "footer-nav-terms-of-use"
        },
        {
          id: "/content/page.jsp?id=text-message-terms",
          targetUrl: "/content/page.jsp?id=text-message-terms",
          displayName: "Text Message Terms",
          "data-gtm-id": "footer-nav-text-message-terms"
        },
        {
          id: "/content/page.jsp?id=rh-canada-shipping",
          targetUrl: "/content/page.jsp?id=rh-canada-shipping",
          displayName: "RH In Canada",
          "data-gtm-id": "footer-nav-rh-in-canada"
        },
        {
          id: "/sitemap.jsp",
          targetUrl: "/sitemap.jsp",
          displayName: "Site Map",
          "data-gtm-id": "footer-nav-site-map"
        }
      ],
      props: {
        style: {
          backgroundColor: "#f5f5f5"
        }
      }
    },
    {
      id: "/trade-sales/index.jsp",
      targetUrl: "https://rh.com/content/category.jsp?context=Trade",
      displayName: "TO THE TRADE",
      "data-gtm-id": "footer-nav-to-the-trade",
      childCategories: [
        {
          id: "/trade-sales/index.jsp",
          targetUrl: "https://rh.com/content/category.jsp?context=Trade",
          displayName: "RH Trade Program",
          "data-gtm-id": "footer-nav-trade-program"
        },
        {
          id: "/content/category.jsp?context=Contract",
          targetUrl: "/content/category.jsp?context=Contract",
          displayName: "Contract Sales",
          "data-gtm-id": "footer-nav-contract-sales"
        }
      ],
      props: {
        style: {
          backgroundColor: "#f5f5f5",
          marginBottom: theme.spacing(-2),
          paddingBottom: theme.spacing(2)
        }
      }
    }
  ];
};
