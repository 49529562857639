import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme
} from "utils/material-ui-core";
import analyticsLoader from "analytics/loader";
import AnimatedHamburgerIcon from "component-animated-hamburger-icon";
import Promo from "component-promo";
import { useEnv } from "hooks/useEnv";
import RHLogo from "component-rh-logo";
import yn from "yn";
import MenuDrawer from "container-menu-drawer";
import SearchDrawer from "container-search-drawer";
import { queryPromoDisplayContent } from "graphql-client/queries/promo-display-content";
import useBrand from "hooks-use-brand/useBrand";
import useState from "hooks/useState";
import RHCartIcon from "icon-cart";
import RHSearchIcon from "icon-search";
import RHGalleryLocatorIcon from "icon-gallery-locator";
import React, { FC, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PROMO_ABOVE_NAV } from "utils/constants";
import { DrawerHeaderContentProps, DrawerHeaderProps } from "./types";
import memoize from "utils/memoize";
import { useLocalization } from "hooks/useLocalization";
import useSite from "hooks/useSite";
import { useCartProjectionAtomValue } from "hooks/atoms";

export const DrawerHeaderContent: FC<DrawerHeaderContentProps> = ({
  position,
  profileStatus,
  home,
  aboveNavPromoDisplay,
  primary,
  styles,
  LogoProps
}) => {
  const theme = useTheme();
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const brand = useBrand();
  const primaryInternal = !!home || !!primary;
  const color = LogoProps?.customColor
    ? LogoProps?.customColor
    : primaryInternal
    ? theme.brandColors.headerIconLight
    : "inherit";

  const handleMenuCloseCb = useCallback(() => {
    setMenuOpen(false);
    setSearchOpen(false);
  }, []);
  const sdOnCloseCb = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const hamburgerOnClickCb = useCallback(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
        { remove: "analytics-catalog" }
      )
    );
    setMenuOpen(true);
  }, []);

  const iconClickCb = useCallback(() => {
    setSearchOpen(true);
  }, []);

  const env = useEnv();
  const prefix = useLocalization();
  const cartProjection = useCartProjectionAtomValue();
  const cartBrokerEnabled = yn(env.FEATURE_PDP_CART_BROKER);
  const quantity = cartBrokerEnabled
    ? cartProjection?.totalItemQuantity
    : profileStatus?.cartCount;

  const navIconColor = LogoProps?.customColor
    ? LogoProps?.customColor
    : primaryInternal
    ? theme.brandColors.headerIconLight
    : window.location.href.includes(
        "/content/category.jsp?context=AlisonBerger"
      )
    ? "#FFFFFF"
    : "#000000";

  return (
    <>
      <AppBar
        position={position}
        color={primaryInternal ? "primary" : "default"}
        style={{
          ...{
            backgroundColor: home || menuOpen ? "transparent" : ""
          },
          ...styles?.root
        }}
      >
        {aboveNavPromoDisplay
          ?.filter(promo => promo.template)
          ?.map(promo => (
            <Promo key={promo.id} data={promo} />
          ))}
        <Toolbar
          style={{
            height: theme.spacing(5),
            borderBottom: primaryInternal ? "none" : "0.5px solid #E0E0E0",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "0px"
          }}
        >
          <RHLogo
            brand={brand}
            onClick={handleMenuCloseCb}
            primary={primaryInternal}
            logoDirName={"logos"}
            {...LogoProps}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconButton id="search-icon" onClick={iconClickCb}>
              <RHSearchIcon style={{ color: navIconColor, height: 24 }} />
            </IconButton>
            <IconButton
              id="drawer-header_cart-button"
              component={RouterLink}
              to={`${prefix}/checkout/shopping_cart.jsp`}
            >
              <RHCartIcon
                style={{ color: navIconColor, height: 24, width: 20 }}
              />
              <Typography
                style={{
                  position: "absolute",
                  margin: "auto",
                  top: 12,
                  fontSize: 10,
                  color
                }}
              >
                {quantity || ""}
              </Typography>
            </IconButton>
            <IconButton component={RouterLink} to="/store-locations/stores.jsp">
              <RHGalleryLocatorIcon
                style={{
                  height: 24,
                  width: 18,
                  color: navIconColor
                }}
              />
            </IconButton>
            <AnimatedHamburgerIcon
              isOpen={menuOpen}
              color={navIconColor}
              onClick={hamburgerOnClickCb}
            />
          </div>
        </Toolbar>
      </AppBar>
      <SearchDrawer open={searchOpen} onClose={sdOnCloseCb} />
      <MenuDrawer open={menuOpen} onClose={handleMenuCloseCb} />
    </>
  );
};

DrawerHeaderContent.defaultProps = {
  position: "static",
  home: false
};

const DHC = memoize(DrawerHeaderContent);

const DrawerHeader: FC<DrawerHeaderProps> = memoize(
  ({ DrawerHeaderContentProps }) => {
    const siteId = useSite();
    const { data: { promoDisplayContent } = {} as Query } = useQuery<Query>(
      queryPromoDisplayContent,
      {
        variables: {
          context: PROMO_ABOVE_NAV,
          siteId
        },
        skip: DrawerHeaderContentProps?.skipAboveNavPromoDisplay ?? false
      }
    );

    const location = useLocation();

    return (
      <DHC
        home={location.pathname === "/" || window.isAEMAuthorMode}
        aboveNavPromoDisplay={promoDisplayContent}
        {...DrawerHeaderContentProps}
      />
    );
  }
);

export default DrawerHeader;
