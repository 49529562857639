import React, { FC, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Divider, Typography, Grid } from "@material-ui/core";
import yn from "yn";
import {
  DE,
  NA_COUNTRIES,
  WORH_MOBILE_FOOTER_CATEGORY_ID,
  omitLinksForUK
} from "utils/constants";
import { useEnv } from "hooks/useEnv";
import useUserPreferences from "hooks/useUserPreferences";
import ExternalLinkArrowIcon from "icons/ExternalLinkArrowIcon";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { queryWORHMenu } from "graphql-client/queries/navigation";
import { filterArrayRecursivelyWith } from "utils/filterArrayRecursivelyWith";
import cloneDeep from "lodash.clonedeep";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import memoize from "utils/memoize";
import useLocale from "hooks-use-locale/useLocale";
import getCountryFromUrl from "utils/getCountryFromUrl";
import EmailSignup from "./EmailSignup";
import { useRhUserAtomValue } from "hooks/atoms";

const RHRFooterContent: FC<{ classes: ClassNameMap; brand?: string }> = ({
  classes,
  brand
}) => {
  const { country } = useUserPreferences();
  const { userType } = useRhUserAtomValue();
  const environment = useEnv();
  const FEATURE_INTERNATIONAL = yn(environment.FEATURE_INTERNATIONAL);
  const locale = useLocale();
  const urlCountry = getCountryFromUrl();
  const isEmailMarketingFooterNA = yn(
    environment?.FEATURE_EMAIL_MARKETING_FOOTER_NA
  );
  const isEmailMarketingFooterUK = yn(
    environment?.FEATURE_EMAIL_MARKETING_FOOTER_UK
  );
  const isEmailMarketingFooterDE = yn(
    environment?.FEATURE_EMAIL_MARKETING_FOOTER_DE
  );

  const showEmailSignupComponent = useMemo((): boolean => {
    if (isEmailMarketingFooterNA && NA_COUNTRIES.includes(urlCountry)) {
      return true;
    }
    if (isEmailMarketingFooterUK && urlCountry === "GB") {
      return true;
    }
    if (isEmailMarketingFooterDE && urlCountry === DE) {
      return true;
    }
    return false;
  }, [urlCountry, isEmailMarketingFooterNA, isEmailMarketingFooterUK]);

  const typographyclasses = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseBody2", "rhBaseCaption"]
  });

  const { data: { worhMenu } = {} as Query } = useQuery<Query>(queryWORHMenu, {
    variables: {
      categoryId: WORH_MOBILE_FOOTER_CATEGORY_ID,
      locale
    }
  });

  const worhMenuMemorized = useMemo(() => {
    const isUK = country === "GB";

    const filteredChildCategories =
      worhMenu?.childCategories?.filter((childCategory: NavigationElement) => {
        return childCategory?.childCategories?.length;
      }) ?? [];

    const newWorhMenu = {
      ...worhMenu,
      childCategories: filteredChildCategories
    };

    if (FEATURE_INTERNATIONAL && isUK) {
      return {
        ...worhMenu,
        childCategories: filterArrayRecursivelyWith({
          array: cloneDeep(newWorhMenu?.childCategories),
          nestedKey: "childCategories",
          cb: (childCategory: NavigationElement) => {
            return !omitLinksForUK?.includes(
              childCategory?.displayName?.toUpperCase() ?? ""
            );
          }
        })?.filter(
          (childCategory: NavigationElement) =>
            childCategory?.childCategories?.length
        )
      };
    }

    return {
      ...worhMenu,
      childCategories:
        worhMenu?.childCategories?.filter(
          (childCategory: NavigationElement) =>
            childCategory?.childCategories?.length
        ) ?? []
    };
  }, [FEATURE_INTERNATIONAL, country, worhMenu]);

  return (
    worhMenuMemorized && (
      <footer>
        <div
          className={classNames([
            classes.footerContainer,
            showEmailSignupComponent && classes.footerContainerwithEmail
          ])}
        >
          <div
            className={classNames([
              classes.separator,
              showEmailSignupComponent && classes.marketingSeparator
            ])}
          >
            <Divider />
          </div>
          {showEmailSignupComponent && <EmailSignup />}
          <div
            className={classNames([
              classes.footerMenus,
              showEmailSignupComponent && classes.footerMenusWithemail
            ])}
          >
            <Grid
              container
              justify="space-between"
              className={classes.grid} /* "grid grid-wide" */
            >
              {worhMenuMemorized?.childCategories?.map(
                ({ displayName, childCategories }, i) => (
                  <div key={`child_category_${i}`}>
                    <div className={classes.gridItemInner}>
                      <Typography
                        className={classNames([
                          typographyclasses.rhBaseBody2,
                          classes.footerHeader
                        ])}
                      >
                        {displayName}
                      </Typography>
                      <ul>
                        {childCategories?.map(
                          (
                            {
                              displayName: subCategoryName,
                              targetUrl,
                              linkType
                            },
                            j
                          ) => {
                            const isExternalLink =
                              targetUrl.startsWith("https://");

                            return !(
                              targetUrl?.includes("gift-registry") &&
                              (brand === "TN" ||
                                userType === "CONTRACT" ||
                                userType === "TRADE")
                            ) ? (
                              <Typography
                                variant="caption"
                                key={`child_sub_category_${i}_${j}`}
                              >
                                <li className={classes.footerItems}>
                                  <a
                                    target={
                                      linkType === "none" || isExternalLink
                                        ? "_blank"
                                        : "_self"
                                    }
                                    href={targetUrl}
                                    data-analytics-parentid={displayName}
                                  >
                                    {subCategoryName}
                                    {isExternalLink && (
                                      <ExternalLinkArrowIcon
                                        width={6.21}
                                        height={6.21}
                                        aria-hidden="false"
                                      />
                                    )}
                                  </a>
                                </li>
                              </Typography>
                            ) : null;
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                )
              )}
            </Grid>
          </div>
        </div>
      </footer>
    )
  );
};

export default memoize(RHRFooterContent);
