import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  Divider,
  MenuItem
} from "@material-ui/core";
import yn from "yn";
import RHStandardSelect from "component-rh-standard-select";
import CountrySelector from "component-country-selector";
import { RHFormTextField } from "component-rh-text-field";
import { useEnv } from "hooks/useEnv";
import { measurements } from "resources/countries-config.json";
import { MEASUREMENT } from "utils/constants";
import useUserPreferences from "hooks/useUserPreferences";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import memoize from "utils/memoize";

const useMenuItemStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "transparent !important",
      minWidth: 202,
      minHeight: 32,
      fontSize: "11px",
      color: "#808080",
      cursor: "pointer",
      padding: "8px 32px !important",
      "&:last-child": {
        borderBottom: "none",
        marginBottom: "10px"
      },
      "&.Mui-selected": {
        backgroundColor: "#F1F1F1 !important",
        color: "#000000"
      },
      "&:hover": {
        backgroundColor: "#F1F1F1 !important"
      }
    }
  })
);

const UserPreferencesV2: FC<{ classes: ClassNameMap }> = ({ classes }) => {
  const { measurement, handleSaveCookies, setMeasurement } =
    useUserPreferences();

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(375));
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const menuItemClasses = useMenuItemStyles();

  const environment = useEnv();

  const FEATURE_INTERNATIONAL_MEASUREMENT = yn(
    environment?.FEATURE_INTERNATIONAL_MEASUREMENT
  );

  const { control } = useForm();

  const handleMeasurementChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMeasurement(e?.target?.value);
      handleSaveCookies({ measurement: e?.target?.value });
    },
    [handleSaveCookies, setMeasurement]
  );

  return (
    <>
      {smDown || xsDown ? (
        <>
          <div className={classes.shippingSeparator}>
            <Divider />
          </div>
          <div className={classes.smCountryPreferences}>
            <CountrySelector />
            {FEATURE_INTERNATIONAL_MEASUREMENT ? (
              <RHFormTextField
                name="measurement"
                id="measurement"
                type="text"
                margin="none"
                select
                control={control}
                inputProps={{
                  "data-testid": "measurement"
                }}
                fullWidth
                value={measurement}
                defaultValue={measurement}
                onInputChange={handleMeasurementChange}
                SelectProps={{
                  classes: {
                    root: classes.smSelect,
                    icon: classes.smSelectIcon
                  },
                  native: false
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                <div className={classes.headerOptionWrapper}>
                  <p className={classes.headerOption}>{MEASUREMENT} </p>
                </div>
                {measurements.map(option => (
                  <MenuItem
                    classes={menuItemClasses}
                    key={`measurement-${option.value}`}
                    value={option.value}
                  >
                    <div>
                      {option?.label.toUpperCase()}{" "}
                      {option?.units.toUpperCase()}
                    </div>
                  </MenuItem>
                ))}
              </RHFormTextField>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className={classes.countryPreferencesWrapper}>
            <div className={classes.countryPreferences}>
              <CountrySelector />
              {FEATURE_INTERNATIONAL_MEASUREMENT ? (
                <RHStandardSelect
                  id="measurement-selection"
                  onChange={handleMeasurementChange}
                  value={measurement}
                  SelectProps={{
                    classes: {
                      root: classes.select,
                      icon: classes.selectIcon
                    },
                    disableUnderline: true,
                    native: false,
                    className: "RHFooterSelect",
                    MenuProps: {
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <div className={classes.headerOptionWrapper}>
                    <p className={classes.headerOption}>{MEASUREMENT} </p>
                  </div>
                  <div className={classes.headerOptionDivider}></div>

                  {measurements.map(item => (
                    <MenuItem
                      classes={menuItemClasses}
                      key={`measurement-${item.value}`}
                      value={item.value}
                    >
                      <div>{item?.label.toUpperCase()}</div>
                    </MenuItem>
                  ))}
                </RHStandardSelect>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memoize(UserPreferencesV2);
